<template>
  <div class="varuscafe-filters">
    <div class="varuscafe-filters--desktop">
      <div
        class="filters"
        v-click-outside="hideFilter"
      >
        <div class="filters-filter filters-filter--category">
          <SfButton
            class="filters__button"
            :class="{ '_active': isActiveCategory }"
            data-transaction-name="VC - Toggle Category"
            @click="toggleCategory"
          >
            <span>{{ $t('Category') }}</span>
            {{ selectedCategory.name }}
          </SfButton>
          <div class="filters-categories">
            <AFilterCategoryItems
              v-if="isActiveCategory"
              :items="categories"
              :level="1"
              :show-all-goods="true"
              :show-all-title="$t('Any category')"
              @select="handlerCategory"
            />
          </div>
        </div>
        <div class="filters-filter filters-filter--kitchen">
          <SfButton
            class="filters__button"
            :class="{ '_active': isActiveKitchen }"
            data-transaction-name="VC - Toggle Kitchen"
            @click="toggleKitchen"
          >
            <span>{{ $t('Kitchen') }}</span>
            {{ selectedKitchen.name }}
          </SfButton>
          <div class="filters-type">
            <AFilterCategoryItems
              v-if="isActiveKitchen"
              :items="kitchen"
              :level="1"
              :show-all-goods="true"
              :show-all-title="$t('Any kitchen')"
              @select="handlerKitchen"
            />
          </div>
        </div>
        <div class="filters-filter filters-filter--menu">
          <SfButton
            class="filters__button"
            :class="{ '_active': isActiveMenu }"
            data-transaction-name="VC - Toggle Menu"
            @click="toggleMenu"
          >
            <span>{{ $t('Menu') }}</span>
            {{ selectedMenu.name }}
          </SfButton>
          <div class="filters-type">
            <AFilterCategoryItems
              v-if="isActiveMenu"
              :items="menu"
              :level="1"
              :show-all-goods="true"
              :show-all-title="$t('Any menu')"
              @select="handlerMenu"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="varuscafe-filters--mobile">
      <MSidebarMobileButtons
        :filters-count="filtersCount"
        :filters-title="$t('Search for dishes')"
        @open="openFilter"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { SfButton } from '@storefront-ui/vue'
import { prepareCategoryMenuItem } from 'theme/helpers'
import MSidebarMobileButtons from 'theme/components/molecules/m-sidebar-mobile-buttons'
import AFilterCategoryItems from 'theme/components/atoms/a-filter-category-items'
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import config from 'config';

export default {
  name: 'MVarusCafeTopFilter',
  props: {
    availableFilters: {
      type: Object,
      defaul: () => ({})
    },
    changeFilters: {
      type: Function
    },
    filtersCount: {
      type: Number,
      default: null
    },
    openFilter: {
      type: Function
    },
    activedFilters: {
      type: Object,
      default: () => ({})
    },
    varusCafeCategory: {
      type: Object,
      default: () => ({})
    }
  },
  provide () {
    const provided = {};
    Object.defineProperties(provided, {
      activeCategoryId: {
        get: () => this.selectedId
      }
    })

    return { provided };
  },
  directives: {
    clickOutside
  },
  components: {
    SfButton,
    AFilterCategoryItems,
    MSidebarMobileButtons
  },
  data () {
    return {
      containerHeight: 0,
      selectFilter: null,
      isActiveCategory: false,
      isActiveKitchen: false,
      isActiveMenu: false,
      selectedKitchen: { name: this.$t('Any kitchen') },
      selectedMenu: { name: this.$t('Any menu') },
      selectedCategory: { name: this.$t('Any category') },
      mutateSelectedId: null,
      mutateMenu: [],
      mutateKitchen: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.$store.dispatch(
          'category-extension/loadCategoriesProductsCount',
          this.varusCafeCategory
        )

        let isFilter = !this.getCurrentCategory.children_count
        const setNameForFilter = (obj) => ({ ...obj, name: obj.label })
        const { menuVaruscafe, kitchenVaruscafe } = this.getCurrentFilters

        if (menuVaruscafe) {
          const obj = menuVaruscafe.slice(-1)[0]
          this.selectedMenu = setNameForFilter(obj)
          isFilter = true
        }

        if (kitchenVaruscafe) {
          const obj = kitchenVaruscafe.slice(-1)[0]
          this.selectedKitchen = setNameForFilter(obj)
          isFilter = true
        }

        this.$emit('change-category-to-lvl2', isFilter)
      }
    },
    getCurrentCategory: {
      immediate: true,
      handler () {
        if (!this.getCurrentCategory.path) {
          return null
        }

        if (!this.getCurrentCategory.children_count) {
          this.selectedCategory = this.getCurrentCategory
          this.$emit('change-category-to-lvl2', true)
        } else {
          this.selectedCategory = { name: this.$t('Any category') }
        }
      }
    },
    selectFilter (newValue) {
      const { type, idfilter } = newValue

      if (!idfilter) {
        this.removeFilter(type)
        return
      }

      const selectedFilter = this.availableFilters[type].find(item => item.id === idfilter.id)

      if (!selectedFilter || this.checkSelectedFilters(type, idfilter)) return

      this.changeFilters(selectedFilter)
    },
    availableFilters (newValue) {
      if (newValue) {
        this.getLabelForCategory(newValue)
      }
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCurrentFilters: 'category-extension/getCurrentFilters',
      categoriesProductsCount: 'category-extension/getCategoriesProductsCount'
    }),
    categories () {
      return this.prepareCategories(this.varusCafeCategory?.children_data || [])
    },
    kitchen: {
      get () {
        return this.mutateKitchen
      },
      set (item) {
        this.mutateKitchen = this.getNameOfFilters(item)
      }
    },
    menu: {
      get () {
        return this.mutateMenu
      },
      set (item) {
        this.mutateMenu = this.getNameOfFilters(item)
      }
    },
    selectedId: {
      get () {
        return this.mutateSelectedId
      },
      set (id) {
        this.mutateSelectedId = id
      }
    }
  },
  created () {
    this.kitchen = this.availableFilters.kitchenVaruscafe
    this.menu = this.availableFilters.menuVaruscafe
  },
  methods: {
    ...mapActions({
      filterByValue: 'blog/filterByValue',
      resetPosts: 'blog/resetPosts'
    }),
    getNameOfFilters (item) {
      return item?.map(({ label, ...rest }) => (
        label ? { ...rest, name: label } : null
      )).filter(Boolean) || []
    },
    checkSelectedFilters (type, idfilter) {
      const isCurrentFilters = Object.keys(this.getCurrentFilters).length
      return isCurrentFilters && this.getCurrentFilters[type]?.some(item => +item.id === +idfilter.id)
    },
    hideFilter () {
      this.isActiveCategory = false
      this.isActiveMenu = false
      this.isActiveKitchen = false
    },
    getLabelForCategory (newFilters) {
      this.kitchen = newFilters.kitchenVaruscafe
      this.menu = newFilters.menuVaruscafe
    },
    toggleCategory () {
      this.isActiveCategory = !this.isActiveCategory
      this.isActiveMenu = false
      this.isActiveKitchen = false
      this.selectedId = this.selectedCategory.id
    },
    toggleKitchen () {
      this.isActiveKitchen = !this.isActiveKitchen
      this.isActiveMenu = false
      this.isActiveCategory = false
      this.selectedId = this.selectedKitchen.id
    },
    toggleMenu () {
      this.isActiveMenu = !this.isActiveMenu
      this.isActiveCategory = false
      this.isActiveKitchen = false
      this.selectedId = this.selectedMenu.id
    },
    resetFilters () {
      this.selectedMenu = { name: this.$t('Any menu') }
      this.selectedKitchen = { name: this.$t('Any kitchen') }
    },
    removeFilter (type) {
      if (type === 'kitchenVaruscafe') {
        this.$emit('clear-all')
      } else {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...this.$route.query,
            menuVaruscafe: undefined
          }
        })
      }
    },
    handlerChangeFilters (type, idfilter) {
      this.selectFilter = { type, idfilter }

      this.isActiveMenu = false
      this.isActiveKitchen = false
    },
    handlerCategory (value) {
      if (value === null) {
        const parent = config.entities.category.categoriesVarusCafeId
        const getParentCategory = prepareCategoryMenuItem(
          this.getCategories.filter(item => item.id === parent || item.slug === 'varuscafe')[0]
        )
        this.$router.push(getParentCategory.link)
      } else {
        const selectedCategory = prepareCategoryMenuItem(this.categories.filter(item => +item.id === +value.id)[0])

        this.$router.push(selectedCategory.link)
      }
      this.resetFilters()
      this.isActiveCategory = false
    },
    handlerKitchen (value) {
      this.handlerChangeFilters('kitchenVaruscafe', value)
    },
    handlerMenu (value) {
      this.handlerChangeFilters('menuVaruscafe', value)
    },
    prepareCategories (categories) {
      return categories
        .map(category => {
          const preparedCategory = prepareCategoryMenuItem(category)

          if (preparedCategory.items) {
            preparedCategory.items = this.prepareCategories(preparedCategory.items)
          }

          return preparedCategory
        })
        .sort((a, b) => a.position - b.position)
    }
  }
}
</script>
<style lang="scss">
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.category--varus-cafe .varuscafe-filters {
  .m-sidebar-mobile-buttons__button--categories {
    display: none;
  }
}
.highlight-varuscafe {
  position: absolute;
  width: 100%;
  background-color: var(--green-light);
  z-index: -1;
  top: 0;
  padding-bottom: px2rem(150);

  @include for-desktop {
    padding-bottom: px2rem(200);
  }

  @include for-tablet {
    padding-bottom: px2rem(175);
  }
}
</style>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.varuscafe-filters {
  &--desktop {
    display: flex;
    column-gap: 10px;
    display: none;

    @include for-desktop {
      display: flex;
    }
  }

  .filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
    box-sizing: border-box;

    &-filter {
      width: calc(50% - var(--spacer-5));
      position: relative;
    }

    &__button {
      width: 100%;
      height: var(--spacer-50);
      color: var(--black);
      padding: var(--spacer-8) var(--spacer-15);
      background-color: var(--light-gray);
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 5px;
      position: relative;
      font-size: var(--font-size-14);

      span {
        color: var(--dark-gray);
        font-size: var(--font-size-12);
        margin-bottom: var(--spacer-5);
      }

      &:after {
        content: '';
        width: .3rem;
        height: .3rem;
        border-bottom: 2px solid var(--white);
        border-right: 2px solid var(--white);
        transform: rotate(-135deg);
        right: var(--spacer-sm);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &._active {
        &:after {
          transform: rotate(45deg);
        }
      }

      &:after {
        border-bottom: 2px solid var(--black);
        border-right: 2px solid var(--black);
      }
    }

    &-categories, &-type {
      position: absolute;
      width: 100%;
      left: 0;
      border-bottom-left-radius: var(--spacer-10);
      border-bottom-right-radius: var(--spacer-10);
      z-index: 2;

      ::v-deep .accordion {
        font-family: var(--font-family-inter);
        display: block;
        width: auto;
        margin-top: var(--spacer-5);
        max-height: px2rem(340);
        overflow-y: scroll;
        align-items: center;
        padding-right: var(--spacer-5);
        padding-left: var(--spacer-5);
        padding-bottom: var(--spacer-20);
        background: var(--light-gray);
        border-radius: var(--spacer-10);
        scrollbar-width: thin;
        scrollbar-color: var(--black);
        box-shadow: var(
            --select-dropdown-box-shadow,
            0 4px 11px rgba(var(--c-dark-base), 0.1)
        );

        @include for-desktop {
          padding-right: 0;
          padding-left: 0;
        }

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(235, 103, 71, 0.3);

          &:hover {
            background-color: var(--orange);
          }
        }

      &-item {
        .accordion-item__header {
          width: 100%;
          transition: none;
        }

        &:first-child {
          @include for-desktop {
            padding-top: var(--spacer-10);
            margin-left: var(--spacer-20);
          }

          & > .accordion-item__header {
            @include for-mobile {
              padding-left: var(--spacer-15);
            }
          }
        }

        &:not(:first-child) {
          margin-left: var(--spacer-15);

          @include for-desktop {
            margin-left: var(--spacer-20);
            margin-right: var(--spacer-20);
          }
        }

        &__header {
          color: var(--black);
          white-space: normal;
          padding: var(--spacer-20) 0 0 0;
          font-style: normal;
          font-weight: normal;
          font-size: var(--font-sm);
          display: inline-block;
          border-radius: var(--spacer-10);
          position: relative;
          transition: color 0.3s ease-in-out;
          cursor: pointer;

          .accordion-item__label {
            position: relative;

            &-count {
              color: var(--neutral-gray);
              padding-left: var(--spacer-10);
              position: absolute;
              left: 100%;
              bottom: 0;
              transition: color .3s ease-in-out;
            }
          }

          &.is-open {
            color: var(--orange);
            background-color: inherit;

            .accordion-item__label {
              &-count {
                color: var(--orange);
                transition: color .3s ease-in-out;
              }
            }
          }

          @include for-desktop {
            &:hover {
              color: var(--orange-hover);

              .accordion-item__label {
                &-count {
                  color: var(--orange);
                  transition: color .3s ease-in-out;
                }
              }
            }
          }

          &--active {
            color: var(--orange);

            .accordion-item__label {
              &-count {
                color: var(--orange);
                transition: color .3s ease-in-out;
              }
            }
          }
        }

        .sf-accordion-item__content {
          padding-bottom: 0;
          padding-top: 0;
          border: none;
          border-left: 1px solid var(--gray);

          & > .accordion--sub {
            margin-left: var(--spacer-20);

            .accordion-item__header.is-open {
              border-radius: 0;
            }
          }
        }

        .accordion-item__first-level + .sf-accordion-item__content {
          border-left: none;
          position: relative;
        }

        &:last-child {
          @include for-desktop {
            padding-bottom: var(--spacer-20);
          }
        }
      }
      }
    }
  }

  &__kitchen,
  &__types {
    flex: 1;
    background: var(--light-gray);
    border-radius: 5px;
    padding: px2rem(8) 0 px2rem(5) 0;
    max-width: 590px;
  }

  ::v-deep .sf-select {
    &__error-message {
      display: none;
    }

    &__dropdown {
      bottom: var(--select-dropdown-bottom, px2rem(20));
    }

    .sf-overlay {
      --sf-overlay-index: 1;
    }

    &__chevron {
      right: px2rem(17);
    }

    &__options {
      max-height: 100%!important;
    }

    &-option {
      font-size: var(--font-size-14);
      color: var(--black);
      --select-selected-padding: 1rem var(--spacer-base) 0 var(--spacer-sm);
      --select-option-padding: var(--spacer-sm);
    }

    &__label {
      font-size: var(--font-size-12);
    }
  }
}
</style>
