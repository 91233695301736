<template>
  <div class="main section notice-18-plus">
    <div class="notice__container">
      <div class="notice__age">
        <div class="notice__age--wrap">
          18<sup>+</sup>
        </div>
      </div>
      <div class="notice__text">
        <p class="notice__text--black">
          {{ $t('The category contains items with an age limit. I certify that I am at least 18 years old.') }}
        </p>
        <p class="notice__text--grey">
          {{ $t('By continuing, you confirm that you have read and agree to the') }}
          <span @click="onUserAgreementClick" class="notice__link">
            {{ $t("rules") }}
          </span>
        </p>
        <SfButton
            class="notice__button--ok"
            @click="closeNotice"
        >
          {{ $t('Confirm') }}
        </SfButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfButton
} from '@storefront-ui/vue';
import { BREAKPOINT_MD } from 'theme/helpers/breakpoints';
import { ModalList } from 'theme/store/ui/modals'
import { mapActions } from 'vuex';

export default {
  name: 'Notice18plus',
  components: {
    SfButton
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    closeNotice () {
      this.$emit('closeNoticeCategory');
      localStorage.setItem('is18plus', true);
    },
    onUserAgreementClick () {
      let windowWith = document.documentElement.clientWidth;

      if (windowWith > [BREAKPOINT_MD - 1]) {
        this.$router.push(this.localizedRoute('/user-agreement'))
      } else {
        this.$store.dispatch('ui/hideBottomNavigation', true)
        this.openModal({ name: ModalList.TermsAndConditionsUserAgreement });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.main.notice-18-plus {
  padding-bottom: var(--spacer-25);

  @include for-tablet {
    padding-bottom: var(--spacer-30);
  }

  .notice {
    &__container {
      display: flex;
      background-color: var(--notice-on-plp-color);
      padding: var(--spacer-20);
      margin-bottom: var(--spacer-20);

      @include for-tablet {
        padding: var(--spacer-25);
      }

      @include for-desktop {
        width: 100%;
        position: relative;
      }
    }

    &__age {
      padding-right: var(--spacer-10);

      @include for-tablet {
        padding-right: var(--spacer-25);
      }

      @include for-desktop {
        padding-right: var(--spacer-25);
      }

      &--wrap {
        font-weight: var(--font-medium);
        font-size: var(--font-sm);
        border: 1px solid var(--dark-orange);
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2rem;
        text-align: center;
        background-color: var(--white);
        box-shadow: inset 0 0 0 3px var(--notice-on-plp-color);

        @include for-tablet {
          font-size: var(--font-xl);
          line-height: 3.5rem;
          width: 4.375rem;
          height: 4.375rem;
          box-shadow: inset 0 0 0 5px var(--notice-on-plp-color);
        }

        @include for-desktop {
          font-size: var(--font-xl);
          line-height: 3.5rem;
          width: 4.375rem;
          height: 4.375rem;
          box-shadow: inset 0 0 0 5px var(--notice-on-plp-color);
        }
      }
    }

    &__text {
      @include for-desktop {
        width: 65%;
      }
      &--black {
        color: var(--black);
        font-size: var(--font-size-13);
        font-weight: var(--font-normal);
        line-height: var(--spacer-22);
        margin: 0;

        @include for-tablet {
          font-size: var(--font-size-14);
        }

        @include for-desktop {
          font-size: var(--font-size-14);
        }
      }

      &--grey {
        color: var(--dark-gray);
        font-size: var(--font-size-12);
        font-weight: var(--font-normal);
        line-height: var(--spacer-20);

        @include for-tablet {
          font-size: var(--font-size-13);
          margin-top: var(--spacer-5);
        }

        @include for-desktop {
          font-size: var(--font-size-13);
          width: calc(100% - var(--spacer-80));
          margin-bottom: 0;
          margin-top: var(--spacer-5);
        }

        .sf-link--primary {
          font-size: var(--font-size-12);
          margin-right: calc(-1 * var(--spacer-3));

          @include for-tablet {
            font-size: var(--font-size-13);
          }

          @include for-desktop {
            font-size: var(--font-size-13);
          }
        }
      }
    }

    &__link {
      color: var(--orange);
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: var(--orange-hover);
      }
    }

    &__button {
      &--ok {
        font-size: var(--font-size-14);
        padding: var(--spacer-8) var(--spacer-16);
        line-height: var(--spacer-24);
        &:hover {
          --button-background: var(--orange-pressed);
          --button-color: var(--white);
        }

        @include for-desktop {
          font-size: var(--font-size-18);
          padding: var(--spacer-16) var(--spacer-34);
          line-height: var(--spacer-24);
          position: absolute;
          right: var(--spacer-25);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
