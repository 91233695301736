<template>
  <div
    id="category"
    class="category category--varus-cafe"
    :class="[
      {
        'category--is-empty': isProductsNotFound
      }
    ]"
  >
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <OVarusCafeCategory
      :is-loading="isLoading"
      :key="`varuscafe_${Object.keys(getCurrentFilters).length}`"
      :class="{'is18plus-overlay': isOverlay}"
      :available-filters="availableFilters"
      :filters-count="activeFiltersCount"
      :category-meta-content="categoryMetaContent"
      :category-products-total="categoryProductsTotal"
      :varus-cafe-category="getVarusCafeCategory"
      @clear-all="clearAllFilters"
      @change-category-to-lvl2="changeVarusCafeCategoryToLvl2"
      @toggle-categories="toggleCategoriesFilters()"
      @open="isFilterSidebarOpen = true"
      @change="changeFilter"
      @close="isFilterSidebarOpen = false"
    />
    <no-ssr>
      <Notice18plus
        v-if="isOverlay"
        @closeNoticeCategory="closeNoticeCategoryFn"
      />
    </no-ssr>
    <div
      class="navbar section"
      :class="{'is18plus-overlay': isOverlay}"
    >
      <div class="navbar__main">
        <SfHeading
          :level="1"
          :title="categoryMetaContent.h1"
          class="navbar__title"
        />
        <div class="navbar__counter">
          <span
            class="navbar__label"
            :class="{ 'navbar__label--hide': isLoading }"
          >
            {{ categoryProductsTotal }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="main section"
     :class="{'is18plus-overlay': isOverlay}"
    >
      <div class="sidebar">
        <MSidebarMobileButtons
          :filters-count="activeFiltersCount"
          :categories-are-shown="categoriesAreShown"
          @open="isFilterSidebarOpen = true"
          @toggle-categories="toggleCategoriesFilters()"
        />
        <no-ssr>
          <MCategoryFilterCategories
              v-if="isDesktop ? true : categoriesAreShown"
              :class="[
                {
                  'm-filter-categories-all': !isShowCategoryFilter
                },
                {
                  'sidebar-hide': isShowCategoryFilter
                }
              ]"
              @toggle-categories="toggleCategoriesFilters()"
          />
        </no-ssr>
        <div
          class="sidebar-filters desktop-only"
          v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
        >
          <MSidebarFilter
            :is-varus-cafe-page="true"
            :available-filters="availableFilters"
            :key="Object.keys(availableFilters).length"
            :filter-labels="filterLabels"
            :actived-filters="getCurrentFilters"
            @change="changeFilter"
            @submit-filter-price="submitFilterPrice"
          />
        </div>
      </div>
      <lazy-hydrate :trigger-hydration="hydrated">
        <div class="products">
          <div class="products__navbar-container desktop-only">
            <div class="navbar__filter">
              <MCategoryFilterStock
                  @stock-filter-changed="stockFilterChanged"
              />
              <MCategoryFilterPromo
                :promo-count="getPromoCount"
                :key="getPromoCount"
                @promo-filter-changed="promoFilterChanged"
              />
            </div>
            <div class="navbar__sort">
              <SfSelect
                class="navbar__select sf-select--white sort-by"
                ref="SortBy"
                :selected="sortOrder"
                @change="changeSortOder"
              >
                <SfSelectOption
                  v-for="option in sortOptions"
                  :key="option.id"
                  :value="option.id"
                  class="sort-by__option"
                >
                  {{ $t(option.label) }}
                </SfSelectOption>
                <template #icon>
                  <span class="dropdown-chevron" />
                </template>
              </SfSelect>
            </div>
          </div>
          <MCategoryFiltersSelected
            v-if="activeFiltersCount !== 0"
            class="desktop-only"
            :key="Object.keys(getCurrentFilters).length"
            :filter-categories="getCurrentFilters"
            :filter-labels="filterLabels"
            @clear="changeFilter"
            @clear-all="clearAllFilters"
            :available-filters="availableFilters"
          />
          <template v-if="!isLoading && isProductsNotFound">
            <SfHeading
              class="products__not-found"
              :title="isProductNone ? $t('No products found in the catalog') : $t('No products found!')"
              :subtitle="isProductNone ? $t('Try using a different combination of filters') : $t('Please select another city or another category')"
            />
            <SfButton
              class="products__change-city"
              v-if="!isProductNone"
              data-transaction-name="Category - Change City"
              @click="openShippingModal()"
            >
              {{ $t('Change city') }}
            </SfButton>
          </template>
          <template v-else>
            <div
              class="banner-info-wrap"
              v-if="productsBeforeInoBanner.length > 0 && !isLoading"
              :class="{'banner-missed': !hasBannerTop}"
            >
              <MBannerCarousel
                :banners="getBannersTop"
                :is-responsive="true"
              />
            </div>
            <MCategoryList
              :is-loading="isLoading"
              :products="productsBeforeInoBanner"
              class-list="products__grid top-grid"
              badge-label="-50%"
              badge-color="color-primary"
              :is-append="isLoadMoreMode"
              :is-placeholder-enabled="!isLoadMoreMode"
              :placeholder-count="cardInRow"
            />
            <div
              class="banner-info-wrap banner-info-wrap--last"
              v-if="productsAfterInoBanner.length > 0 && !isLoading"
              :class="{'banner-missed': !hasBannerMiddle}"
            >
              <MBannerCarousel
                :banners="getBannersMiddle"
                :is-responsive="true"
              />
            </div>
            <MCategoryList
              :is-loading="isLoading"
              :products="productsAfterInoBanner"
              class-list="products__grid main-grid"
              badge-label="-50%"
              badge-color="color-primary"
              :last-row-class="hasBannerMiddle"
              :is-append="isLoadMoreMode"
              :placeholder-count="productsAfterInoBannerCount"
            />
            <div
              v-if="totalPages > 1"
              class="bottom-plp-block"
            >
              <SfOPagination
                class="products__pagination"
                :current="currentPage"
                :total="totalPages"
                :count-range-pages="countRangePages"
                :key="currentCategory.id"
                :scroll-top="true"
                @loadMoreFromPagEmit="loadMoreFromPagEmitFunction"
                @countRangePagesEmit="countRangePagesEmitFunction"
              />
            </div>
            <!--          <MPopularCategories v-if="false" />-->
            <MRelatedCategories
              v-if="getRelatedCategories"
              :items="getRelatedCategories"
            />
          </template>
        </div>
      </lazy-hydrate>
    </div>
    <ASmoothReflow
      class="category-footer__wrapper"
      v-intersect.once="{ handler: onIntersect, cfg: { rootMargin: '0px' }}"
    >
      <div class="category-footer">
        <template v-if="hasIntersected">
          <MRecentlyViewedProducts :show-qty-permanently="true" />
          <MAdvantages />
          <OFAQ
            v-if="questions"
            :questions="questions"
          />
          <OSection
            v-if="false"
            :title-heading="$t('Cook with VARUS')"
            class="section"
          >
            <template #link>
              <SfLink link="#" target="_blank" class="sf-link--primary">
                {{ $t("Read recipes") }}
              </SfLink>
            </template>
            <MRecipeCarousel class="flex" />
          </OSection>
        </template>
        <MSeoBlock
          v-if="!categoryMetaContent.isUrlInExclusionList"
          :seo-html="getSeoHtml"
        />
      </div>
    </ASmoothReflow>
    <MSidebarMobileFilters
      :is-varus-cafe-page="true"
      :key="activeFiltersCount"
      :is-open="!!isFilterSidebarOpen"
      @close="isFilterSidebarOpen = false"
      @open="filterSidebarInner"
      @clear="clearAllFilters"
      @promo-filter-changed="promoFilterChanged"
      @stock-filter-changed="stockFilterChanged"
      :sort-label="sortLabel"
      :available-filters="varusCafeCategoryMobileFilters"
      :filters-count="activeFiltersCount"
      :filter-labels="filterLabels"
      :filter-actived="getCurrentFilters"
      :promo-count="getPromoCount"
    />
    <MSidebarFiltersInner
      :is-open="!!filterSidebarInnerOpen"
      :filter-name="filterSidebarInnerOpen"
      :filter-labels="filterLabels"
      @change="changeFilter"
      @change-sort-order="changeSortOder"
      @close="filterSidebarInnerOpen = ''"
      :sort-options="sortOptions"
      :actived-filters="getCurrentFilters"
      :sort-order="sortOrder"
      :available-filters="varusCafeCategoryMobileFilters"
      :filters-count="activeFiltersCount"
      @submit-filter-price="submitFilterPrice"
    />

    <JsonLdCategory :products-total="getCategoryProductsTotal"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import castArray from 'lodash/castArray';
import MBreadcrumbs from '../components/molecules/m-breadcrumbs';
import config from 'config';
import {
  isServer
} from '@vue-storefront/core/helpers';
import { htmlDecode } from '@vue-storefront/core/filters';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import {
  prepareCategoryProduct
} from 'theme/helpers';
import {
  SfButton,
  SfLink,
  SfSelect,
  SfHeading
} from '@storefront-ui/vue';
import SfOPagination from 'theme/components/storefront-override/SfOPagination';
import { eSputnikEvent } from 'theme/helpers/es';
import MCategoryFilterCategories from 'theme/components/molecules/m-category-filter-categories';
import MSidebarFilter from '../components/molecules/m-sidebar-filter';
import MCategoryFilterPromo from 'theme/components/molecules/m-category-filter-promo';
import MSeoBlock from 'theme/components/molecules/m-seo-block';
import MAdvantages from 'theme/components/molecules/m-advantages';
import MRecentlyViewedProducts from 'theme/components/molecules/m-recently-viewed-products';
import MRecipeCarousel from 'theme/components/molecules/m-recipe-carousel';
import OSection from 'theme/components/organisms/o-section';
// import MPopularCategories from 'theme/components/molecules/m-popular-categories';
import MSidebarMobileButtons from 'theme/components/molecules/m-sidebar-mobile-buttons';
import MSidebarMobileFilters from 'theme/components/molecules/m-sidebar-mobile-filters';
import MSidebarFiltersInner from 'theme/components/molecules/m-sidebar-filters-inner';
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel';
import DeviceType from 'theme/mixins/DeviceType';
import JsonLdCategory from 'theme/components/json-ld/json-ld-category';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import Notice18plus from 'theme/components/molecules/m-notice-18plus'
import MCategoryFiltersSelected from 'theme/components/molecules/m-category-filters-selected';
import MCategoryList from 'theme/components/molecules/m-category-list';
import LazyHydrate from 'vue-lazy-hydration';
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import { metaCategory, getMetaContent } from 'theme/meta/varus-cafe';
import ASmoothReflow from '../components/atoms/a-smooth-reflow';
import intersect from '../directives/intersect';
import MRelatedCategories from 'theme/components/molecules/m-related-categories.vue';
import NoSSR from 'vue-no-ssr'
import OVarusCafeCategory from 'theme/components/organisms/o-varuscafe-category'
import GoogleTagManager from 'theme/mixins/gtm';
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';
import Delivery from 'theme/mixins/Delivery';
import OFAQ from 'theme/components/organisms/o-faq.vue';
import MCategoryFilterStock from 'theme/components/molecules/m-category-filter-stock';
import { Logger } from '@vue-storefront/core/lib/logger'

let isLoadMore = false;

const THEME_PAGE_SIZE = 40;

const composeCategoryState = async (store, route, forceLoad = false) => {
  const filters = { slug: route.params.slug || 'varuscafe' }

  const cachedCategory = store.getters['category-next/getCategoryFrom'](
    route.path
  );

  if (cachedCategory && !forceLoad) {
    return cachedCategory
  }

  return store.dispatch('category-next/loadCategory', { filters })
}

const loadProducts = async (store, route, currentCategory, page = 0) => {
  const currentCategoryId = currentCategory?.id
  const toPromise = [
    store.dispatch('category-extension/loadCategoryProducts', {
      route: route,
      category: currentCategory,
      pageSize: THEME_PAGE_SIZE,
      start: page ? (page - 1) * THEME_PAGE_SIZE : page
    }),
    store.dispatch(
      'category-extension/loadCategoryBreadcrumbs',
      {
        category: currentCategory,
        currentRouteName: currentCategory.name,
        omitCurrent: true
      }
    ),
    store.dispatch('homepage/loadSampleProductsCategory', currentCategoryId)
  ]

  if (currentCategory.parent_id === config.entities.category.categoriesRootCategoryId) {
    toPromise.push(
      store.dispatch('category-extension/loadChildCategories', {
        category: currentCategory,
        pageSize: 12
      })
    )
  }

  catalogHooksExecutors.categoryPageVisited(currentCategory);

  await Promise.all(toPromise)

  if (!isServer) {
    store.dispatch('ui/categoryLoader', false)
  }
}

export default {
  name: 'VarusCafe',
  components: {
    JsonLdBreadcrumb,
    JsonLdCategory,
    MCategoryFilterCategories,
    MCategoryFiltersSelected,
    MSeoBlock,
    MBreadcrumbs,
    SfButton,
    SfLink,
    SfSelect,
    SfHeading,
    MCategoryFilterPromo,
    MRecentlyViewedProducts,
    MAdvantages,
    MRecipeCarousel,
    OSection,
    SfOPagination,
    // MPopularCategories,
    MSidebarFilter,
    MSidebarMobileButtons,
    MSidebarMobileFilters,
    MSidebarFiltersInner,
    MBannerCarousel,
    Notice18plus,
    MCategoryList,
    LazyHydrate,
    ASmoothReflow,
    MRelatedCategories,
    'no-ssr': NoSSR,
    OVarusCafeCategory,
    OFAQ,
    MCategoryFilterStock
  },
  mixins: [
    DeviceType,
    GoogleTagManager,
    Delivery
  ],
  directives: { intersect },
  data () {
    return {
      isLoadMoreMode: false,
      isVarusCafeCategoryLvl2: false,
      currentPage: 1,
      browserWidth: 0,
      isFilterSidebarOpen: false,
      filterSidebarInnerOpen: '',
      unsubscribeFromStoreAction: null,
      aggregations: null,
      countRangePages: [],
      categoriesAreShown: false,
      isShowCategoryFilter: false,
      openNotice: true,
      is18plus: false,
      selectedFilters: {},
      updateKey: 0,
      hydrated: false,
      hasIntersected: false
    };
  },
  computed: {
    ...mapState({
      getAggregations: state => state['category-extension'].aggregations,
      getCategories: state => state['category'].list,
      getBreadcrumbsRoutes: state => state['breadcrumbs'].routes,
      getBreadcrumbsCurrent: state => state['breadcrumbs'].current,
      productsInCart: state => state['cart'].cartItems,
      getAttributeListByCode: state => state['attribute'].list_by_code,
      getFiltersMap: state => state['category-extension'].filtersMap,
      categories: state => state['category-extension'].categories,
      getFilterPrice: state => state['category-extension'].filterPrice,
      getDefaultShop: state => state['shipping'].defaultShop,
      categoryLoader: state => state.ui.categoryLoader,
      productLoader: state => state.ui.productLoader,
      questions: state => state.faq.questions
    }),
    ...mapGetters({
      getCurrentSearchQuery: 'category-extension/getCurrentSearchQuery',
      getCategoryProducts: 'category-next/getCategoryProducts',
      getCurrentCategory: 'category/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAvailableFilters: 'category-extension/getAvailableFilters',
      getAvailableFiltersFrom: 'category-extension/getAvailableFiltersFrom',
      getCurrentFilters: 'category-extension/getCurrentFilters',
      getSystemFilterNames: 'category-next/getSystemFilterNames',
      isLoggedIn: 'user/isLoggedIn',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent',
      getBannersMiddle: 'promoted/getBannersMiddle',
      getBannersTop: 'promoted/getBannersTop',
      shippingDetails: 'shipping/getShippingDetails',
      getVarusCafeCategory: 'category-extension/getVarusCafeCategory'
    }),
    isProductsNotFound () {
      return (this.productsBeforeInoBanner.length === 0) || this.isCategoryEmpty
    },
    isLoading () {
      return this.categoryLoader || this.productLoader
    },
    isOverlay () {
      return this.currentCategory.is_18_plus && this.openNotice && !this.is18plus
    },
    selectedLanguage () {
      this.clearAllFilters();
      return currentStoreView().storeCode
    },
    getRelatedCategories () {
      return this.currentCategory?.related_category
    },
    getPromoCount () {
      if (!this.getAggregations.hasOwnProperty('agg_terms_has_promotion_in_stores')) {
        return 0
      }

      const buckets = this.getAggregations['agg_terms_has_promotion_in_stores']?.buckets || []

      const shopId = this.shippingDetails?.shopId || this.getDefaultShop?.id

      const item = buckets.find(i => +i.key === shopId)

      return item?.doc_count || 0
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0;
    },
    isProductNone () {
      return !this.isLoading && this.products.length === 0;
    },
    breadcrumbs () {
      return this.getBreadcrumbsRoutes
        .map(route => ({
          text: htmlDecode(route.name),
          route: {
            link: route.route_link
          }
        }))
        .concat({
          text: htmlDecode(this.getBreadcrumbsCurrent)
        });
    },
    products () {
      return this.getCategoryProducts.map(prepareCategoryProduct);
    },
    cardInRow () {
      return this.isTablet ? 6 : 8
    },
    productsBeforeInoBanner () {
      return this.products.slice(0, this.cardInRow);
    },
    productsAfterInoBanner () {
      return this.products.slice(this.cardInRow, this.products.length);
    },
    hasBannerTop () {
      return !!this.getBannersTop.length
    },
    hasBannerMiddle () {
      return !!this.getBannersMiddle.length
    },
    productsAfterInoBannerCount () {
      return THEME_PAGE_SIZE - this.cardInRow
    },
    totalPages () {
      return Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE);
    },
    sortOrder () {
      if (this.getCurrentSearchQuery['sort'] !== undefined) {
        return this.getCurrentSearchQuery['sort'];
      } else {
        return 'popularity:desc';
      }
    },
    sortOptions () {
      return Object.entries(config.products.sortByAttributes).map(attribute => {
        const [label, id] = attribute;
        return { id, label };
      });
    },
    sortLabel () {
      const selectedSortOrder =
        this.sortOptions.find(sortOption => sortOption.id === this.sortOrder) ||
        {};
      return selectedSortOrder.label || '';
    },
    availableFilters () {
      const filtersArray = this.getFiltersMap[this.currentCategory.id] || {};
      const { getSystemFilterNames, selectedFilters } = this;

      const result = Object.entries(filtersArray)
        .filter(([filterType, filters]) => filters.length && !getSystemFilterNames.includes(filterType))
        .reduce((result, [filterType, filters]) => {
          const modifiedFilters = filters
            .filter(filter => filter.id !== '0')
            .map(filter => ({
              ...filter,
              count: this.getFilterCount(filter) || 0,
              color: filterType === 'color'
                ? (config.products.colorMappings && config.products.colorMappings[filter.label]) || filter.label
                : undefined
            }));
          result[filterType] = modifiedFilters;
          return result;
        }, { price: this.getPrice() });

      Object.keys(selectedFilters).forEach(filterType => {
        if (result[filterType]) {
          result[filterType] = selectedFilters[filterType];
        }
      });

      return result;
    },
    currentCategory () {
      const params = { slug: 'varuscafe' }
      return this.getCurrentCategory(params)
    },
    varusCafeCategoryMobileFilters () {
      const parent = this.currentCategory
      const getCategoryChildren =
        this.getCategories.length ? this.getCategories.find(c => c.id === parent || c.slug === 'varuscafe') : []

      const defaultFilter = {
        id: -1,
        label: this.$t('Any category'),
        link: '/varuscafe',
        type: 'category'
      }

      const categoryFilters = {
        [`${config.attributesCodes.varusCafe}`]: getCategoryChildren?.children_data?.length
          ? getCategoryChildren.children_data.map(item => ({
            id: item.id,
            type: 'category',
            label: item.name,
            count: item.product_count,
            link: formatCategoryLink(item)
          })
          ) : {}
      }

      if (
        categoryFilters[`${config.attributesCodes.varusCafe}`] &&
          Array.isArray(categoryFilters[`${config.attributesCodes.varusCafe}`])
      ) {
        categoryFilters[`${config.attributesCodes.varusCafe}`]?.unshift(defaultFilter)
      }

      return { ...this.availableFilters, ...categoryFilters }
    },
    filterLabels () {
      const attrArr = Object.values(this.getAttributeListByCode)
      const labels = {}
      attrArr.forEach(item => { labels[`${item.attribute_code}`] = item.frontend_label === undefined ? '' : item.frontend_label })
      return labels
    },
    activeFiltersCount () {
      let counter = 0;

      if (this.getCurrentSearchQuery.sort !== undefined && this.getCurrentSearchQuery.sort !== 'popularity:desc') {
        counter = 1;
      } else {
        counter = 0;
      }

      if (this.$route.params['has_promotion_in_stores'] !== undefined) {
        counter += 1;
      }

      Object.keys(this.getCurrentFilters).forEach(key => {
        counter += this.getCurrentFilters[key].length;
      });

      return counter;
    },
    isFilterActive () {
      return filter =>
        castArray(this.getCurrentFilters[filter.type]).find(
          variant => variant && variant.id === filter.id
        ) !== undefined;
    },
    getSeoHtml () {
      return this.currentCategory.description
    },
    categoryProductsTotal () {
      return this.$tc('{count} items', this.getCategoryProductsTotal)
    },
    categoryMetaContent () {
      return getMetaContent()
    },
    currentCategoryId () {
      return this.currentCategory.id
    }
  },
  watch: {
    currentCategory (newCat, oldCat) {
      const { name, id } = newCat;

      if (name) {
        this.$store.dispatch('promoted/updateBannerInform', { categoryId: id });
      }

      if (id === oldCat?.id) return

      this.admitadRetagCategoryPage(id)
    },
    currentCategoryId (newCat, oldCat) {
      if (newCat !== oldCat) {
        this.eSputnikActions()
      }
    },
    sortOrder () {
      if (this.currentPage > 1) {
        this.changePage();
      }
    },
    categoryLoader (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.gtmProductsHandler(this.products, 'view_item_list')
        this.gtmProductsHandlerAds(this.products, 'view_item_list_ads')
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        if (isServer) return
        if (from !== undefined && to.path !== from.path) {
          this.$store.dispatch('category-extension/clickLoadMore', { enable: false });
          // this.clearAllFilters()
        }
        if ((to.query.page || to.params.isFirstPage) && (!from || to.path === from.path)) {
          this.changePage(parseInt(to.query.page) || 1);
        } else {
          this.initPagination();
        }

        if (to.path.includes('varuscafe')) {
          this.isShowCategoryFilter = true
        }

        this.categoriesAreShown = this.isDesktop;
      }
    },
    'shippingDetails.shopId': {
      handler: async function (newValue, oldValue) {
        if (!newValue || newValue === oldValue) return

        const page = this.$route.query.page || this.$route.params.page || 0

        await this.$store.dispatch('category-extension/loadCategoryProducts', {
          route: this.$route,
          category: this.currentCategory,
          pageSize: THEME_PAGE_SIZE,
          start: page ? (page - 1) * THEME_PAGE_SIZE : page
        })

        if (this.currentCategory.parent_id === config.entities.category.categoriesRootCategoryId) {
          await this.$store.dispatch('category-extension/loadChildCategories', {
            category: this.currentCategory
          });
        }
      }
    }
  },
  async asyncData ({ store, route, context }) {
    if (isLoadMore) {
      isLoadMore = false;
      return;
    }

    // this is for SSR purposes to prefetch data - and it's always executed before parent component methods
    if (context) {
      context.output.cacheTags.add('category');
    } else {
      store.dispatch('ui/categoryLoader', true)
    }

    try {
      const [currentCategory] = await Promise.all([
        composeCategoryState(store, route),
        store.dispatch('ui/loadCatalogMenu'),
        store.dispatch('homepage/loadAdvantagesContent')
      ]);

      store.dispatch('faq/loadFAQForCategory', { id: currentCategory.id })

      if (currentCategory.id === config.entities.category.categoriesRootCategoryId) {
        return
      }

      const loadProductsPromise = loadProducts(store, route, currentCategory, route?.query?.page || 0)

      if (isServer) {
        await loadProductsPromise
      }
    } catch (e) {
      store.dispatch('ui/categoryLoader', false)
    }
  },
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      try {
        await vm.$store.dispatch('ui/categoryLoader', true)

        vm.hydrated = true

        const category = await composeCategoryState(vm.$store, to, true);

        if (category.id === config.entities.category.categoriesRootCategoryId) {
          const homepage = localizedRoute('/', currentStoreView().storeCode)
          await vm.$router.replace(homepage)
          return
        }

        await loadProducts(vm.$store, to, category, to?.query?.page || to?.params?.page || 0)
      } catch (error) {
        Logger.log('beforeRouteEnter' + error)();
      } finally {
        await vm.$store.dispatch('ui/categoryLoader', false)
      }
    });

    return next()
  },
  mounted () {
    this.eSputnikActions()

    this.unsubscribeFromStoreAction = this.$store.subscribeAction(action => {
      if (action.type === 'category-extension/loadAvailableFiltersFrom') {
        this.aggregations = action.payload.aggregations;
      }
    });
    window.addEventListener('resize', this.getBrowserWidth);
    this.getBrowserWidth();
    this.hydrated = true
  },
  beforeMount () {
    if (localStorage.getItem('is18plus')) {
      this.is18plus = localStorage.getItem('is18plus');
    }
  },
  beforeDestroy () {
    this.unsubscribeFromStoreAction();
    window.removeEventListener('resize', this.getBrowserWidth);
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      loadProductsOptionByValues: 'product-extension/loadProductsOptionByValues',
      setCategory: 'category-extension/setCategories'
    }),
    async setCurrentCategory (category) {
      this.toggleCategoriesFilters();
      const query = { q: this.$route.query.q };

      if (category !== null) {
        query['cat'] = category.id;
      }

      await this.setCategory(category)
      this.clearAllFilters();
      await this.$router.push({ query })
    },
    async loadMoreFromPagEmitFunction () {
      await this.$store.dispatch('ui/categoryLoader', true)

      this.isLoadMoreMode = true;
      isLoadMore = true;
      this.countRangePages.push(this.currentPage);
      await this.$store.dispatch('category-extension/loadMoreCategoryProducts', { route: this.$store.state.route, currentCategory: this.currentCategory });
      this.currentPage += 1;
      this.countRangePages.push(this.currentPage);
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: this.currentPage
        },
        params: {
          loadMore: true,
          page: this.currentPage
        }
      });
      this.isLoadMoreMode = false;
      await this.$store.dispatch('ui/categoryLoader', false)
    },
    countRangePagesEmitFunction () {
      this.countRangePages = [];
    },
    getBrowserWidth () {
      return (this.browserWidth = window.innerWidth);
    },
    async changePage (page = this.currentPage) {
      const start = (page - 1) * THEME_PAGE_SIZE;

      if (
        start < 0 ||
        start >= this.getCategoryProductsTotal ||
        this.getCategoryProductsTotal < THEME_PAGE_SIZE
      ) {
        return;
      }

      this.currentPage = page;
    },
    initPagination () {
      this.currentPage = 1;
      this.countRangePages = [];
    },
    changeSortOder (sortOrder) {
      if (this.getCurrentSearchQuery.sort !== sortOrder) {
        this.$store.dispatch('category-extension/switchSearchFilters', [
          { id: sortOrder, type: 'sort' }
        ]);
      }
    },
    stockFilterChanged ({ id, type }) {
      const currentStockShop = this.getCurrentSearchQuery.stock_shop;
      if (currentStockShop !== id) {
        const queryFilter = id === 'in_stock' ? id : '';
        this.$store.dispatch('category-extension/switchSearchFilters', [
          { id: queryFilter, type: type }
        ]);
      }
    },
    async changeFilter ({ filter, currentFilterValues, filterType }) {
      if (filterType === 'price') {
        filter.attribute_code = filterType;
      } else {
        this.selectedFilters[filterType] = currentFilterValues
      }

      await this.$store.dispatch('category-extension/switchSearchFilters', [filter]);
    },
    changeVarusCafeCategoryToLvl2 (status) {
      this.isVarusCafeCategoryLvl2 = status;
    },
    clearAllFilters (keepSort = false) {
      this.selectedFilters = {}
      this.$store.dispatch('category-extension/filterCategoryPrice', {});
      this.$store.dispatch('category-extension/resetSearchFilters', { keepSort });
    },
    getFilterCount (filter) {
      const aggregations = [
        `agg_range_${filter.type}`,
        `agg_terms_${filter.type}`,
        `agg_terms_${filter.type}_options`
      ];

      return aggregations.reduce((result, aggregation) => {
        const buckets = this.getAggregations?.[aggregation]?.buckets;
        const bucket = buckets?.find(bucket => String(bucket.key) === String(filter.id));
        return result + (bucket?.doc_count || 0);
      }, 0);
    },
    filterSidebarInner (filter) {
      this.filterSidebarInnerOpen = filter
    },
    toggleCategoriesFilters () {
      this.categoriesAreShown = !this.categoriesAreShown
    },
    promoFilterChanged (filter) {
      this.changeFilter({ filter })
    },
    closeNoticeCategoryFn () {
      this.openNotice = false;
    },
    getPrice () {
      const { agg_price } = this.getAggregations;
      const price = {
        type: 'price',
        from: 0,
        to: 0
      };

      if (agg_price && Object.keys(agg_price).length > 0) {
        const { filter } = agg_price;
        price.from = filter['price_max']?.value ? Number(filter['price_max'].value).toFixed(0) : 0;
        price.to = filter['price_min']?.value ? Math.trunc(filter['price_min'].value) : 0;
      }

      return price;
    },
    async submitFilterPrice (minPrice, maxPrice) {
      const filter = {
        id: `${minPrice}-${maxPrice}`,
        type: 'price',
        attribute_code: 'price',
        from: Number(minPrice),
        to: Number(maxPrice)
      };
      await Promise.all([
        this.$store.dispatch('category-extension/filterCategoryPrice', filter),
        this.changeFilter({ filter, filterType: 'price' })
      ]);
    },
    onIntersect (entry) {
      entry.isIntersecting &&
      !this.loading &&
      !this.loaded &&
      (this.hasIntersected = true)
    },
    async eSputnikActions () {
      eSputnikEvent('CategoryPage', this.currentCategory.name)
    }
  },
  metaInfo: metaCategory
};
</script>

<style lang="scss" scoped>
@import "~theme/css/mixins";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.category {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.category-footer {
  padding: 0;

  &__wrapper {
    position: relative;
  }

  @include for-desktop {
    padding: 0 var(--spacer-10);
  }

  .m-seo-block {
    padding: 0 var(--spacer-10) var(--spacer-100);

    @include for-tablet {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-18);
    }
  }

  .m-recently-viewed-products {
    ::v-deep {

      @include for-tablet {
        padding-top: var(--spacer-20);
      }
      .o-section {
        padding: 0;

        &--center {
          padding: 0;
        }

        &__heading {
          @include for-mobile {
            padding: 0 var(--spacer-10) var(--spacer-15) var(--spacer-10);
          }

          @include for-tablet {
            padding: 0 var(--spacer-16) var(--spacer-15) 0;
          }
        }
      }

      @media (min-width: $tablet-min) {
        .sf-carousel__slides {
          padding-bottom: var(--spacer-22);
        }
      }

      .glide__slide .sf-product-card {
        padding: 0;
      }
    }

    @include for-tablet {
      margin-left: var(--spacer-16);
    }
  }

  .m-advantages {
    padding-top: var(--spacer-55);
    padding-bottom: var(--spacer-50);

    @include for-tablet {
      padding-top: var(--spacer-60);
      padding-bottom: var(--spacer-60);
      line-height: var(--spacer-34);
    }

    @include for-desktop {
      padding-top: var(--spacer-30);
      padding-bottom: var(--spacer-2xl);
      line-height: var(--spacer-34);
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  &.is18plus-overlay {
    position: relative;
    filter: blur(var(--spacer-5));
    cursor: auto;
    pointer-events: none;

    .m-related-categories {
      ::v-deep {
        ul {
          filter: blur(var(--spacer-3));
        }
      }
    }
  }

  @include for-desktop {
    flex-direction: row;
  }
  &.section {
    padding: 0 var(--spacer-10) var(--spacer-50);

    @include for-tablet {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-18);
    }
  }
}
.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base) var(--spacer-sm);
}

.navbar {
  position: relative;
  display: flex;

  &.is18plus-overlay {
    filter: blur(var(--spacer-5));
  }

  ::v-deep .sf-heading__title {
    @include for-mobile {
      --heading-title-color: --black;
      --heading-title-font-size: var(--font-size-20);
      line-height: 1.2;
      text-align: left;
    }

    @include for-tablet {
      --heading-title-font-size: var(--font-size-32);
    }
  }

  &.section {
    padding: 0 var(--spacer-10);
    margin-bottom: var(--spacer-10);
    min-height: px2rem(24.5);

    @media (min-width: $tablet-min) {
      padding-left: var(--spacer-16);
      padding-right: var(--spacer-16);
      margin-bottom: var(--spacer-sm);
      min-height: px2rem(38.5);
    }
  }

  &__aside {
    align-items: center;
    display: flex;
    flex: 0 0 15%;
    padding: var(--spacer-sm) var(--spacer-sm);
    border: solid var(--c-light);
    border-width: 0 1px 0 0;
  }

  &__main {
    .sf-heading {
      --heading-padding: 0 var(--spacer-20) 0 0;
    }
  }

  &__filters-button {
    display: flex;
    align-items: center;
    font-size: 1rem;
    grid-column: 1;
    justify-self: start;

    &:hover {
      .sf-icon {
        fill: var(--c-primary);
      }
    }

    @include for-mobile {
      --button-text-transform: uppercase;
      font-size: var(--font-xs);

      &.sort-by__button {
        order: 1;
      }
    }
  }

  &__filter {
    display: flex;
    grid-area: filter;
  }

  &__filters-icon {
    margin: 0 var(--spacer-sm) 0 0;
  }

  &__label {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    margin: 0 var(--spacer-2xs) 0 0;
    font-size: var(--font-size-13);
    white-space: nowrap;

    transition: opacity 200ms;
    opacity: 1;

    &--hide {
      opacity: 0;
    }
  }

  &__select {
    --select-padding: 0 var(--spacer-lg) 0 var(--spacer-2xs);
    --select-margin: 0;
    --select-selected-padding: 0 var(--spacer-xl) 0 0;

    .dropdown-chevron {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-self: end;
    grid-area: sort;

    .sf-select-option--is-active {
      color: var(--c-primary);
      pointer-events: none;
    }

    @include for-desktop {
      justify-self: end;
    }
  }

  &__counter {
    display: inline;
    font-family: var(--font-family-secondary);
  }

  &__view {
    display: flex;
    align-items: center;
    margin: 0 var(--spacer-xl);

    @include for-desktop {
      margin: 0 0 0 var(--spacer-2xl);
    }

    &-icon {
      cursor: pointer;
    }

    &-label {
      margin: 0 var(--spacer-sm) 0 0;
      font: var(--font-medium) var(--font-xs) / 1.6 var(--font-family-secondary);
      text-decoration: underline;
    }
  }

  &__title {
    display: inline;

    ::v-deep .sf-heading__title {
      padding: 0;
      display: inline;
      @include header-title;
    }
  }
}

.sort-by {
  --select-dropdown-z-index: 3;
  flex: unset;
  padding: 0;

  ::v-deep {
    .sf-select__dropdown {
      left: initial;
      min-width: max-content;
      right: 0;
    }

    .sf-select-option {
      cursor: pointer;
    }
  }

  // This is needed to hide SfSelect main element on mobile view.
  // This code can be removed when SfSelect supports better customization.
  @include for-mobile {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    --select-dropdown-z-index: 2;

    ::v-deep .sf-select__cancel {
      margin: 16px;
      box-shadow: 4px 12px 24px rgba(119, 121, 122, 0.25);
      --button-width: calc(100% - 32px);
    }
  }
}

.sidebar {
  flex: 100%;

  @include for-desktop {
    padding: 0;
    flex: 0 0 13.75rem;
    border: none;
    margin-right: var(--spacer-20);
    max-width: 13.75rem;
  }

  &-filters {
    background: var(--light-gray);
    border-radius: 10px;
    padding: var(--spacer-sm) var(--spacer-20);
    margin-top: var(--spacer-10);
    --sidebar-title-display: none;
    --sidebar-top-padding: 0;

    @include for-desktop {
      --sidebar-content-padding: 0 var(--spacer-xl);
      --sidebar-bottom-padding: 0 var(--spacer-xl);
    }
  }

  &-hide {
    display: none!important;

    &--mobile {
      display: none;
    }
  }
}

.sidebar-filters {
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
    padding: 0 var(--spacer-20);
    max-width: px2rem(220);
  }
}

.list {
  --menu-item-font-size: var(--font-sm);

  &__item {
    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  overflow: hidden;

  &__navbar-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-zircon);
    padding-bottom: var(--spacer-10);
    margin-bottom: var(--spacer-10);
  }

  ::v-deep &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep &__grid {
    @include for-desktop {
      width: 100%;

      ::v-deep .sf-product-card {
        &.sf-product-card--out-of-stock-container:hover:before {
          bottom: calc(-1 * var(--spacer-5));
        }
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-35));
          }
          .sf-product-card__hidden-basket {
            position: absolute;
          }
        }
        .sf-product-card__hidden-basket {
          position: absolute;
          left:5px;
          bottom: -15px;
        }
      }
    }

    &.top-grid {
      padding-top: var(--spacer-10);
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.2s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  &__load-more {
    background-color: transparent;
    font-weight: 400;
    color: var(--orange);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--orange-hover);
    }
  }

  @include for-desktop {
    margin: 0;

    &__pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      background-color: var(--light-gray);
      padding: var(--spacer-20);
    }

    &__product-card {
      flex: 1 1 25%;
    }

    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }

  &__not-found {
    margin-top: var(--spacer-30);
    --heading-text-align: left;

    ::v-deep .sf-heading__title {
      color: var(--dark-gray);
      margin-bottom: var(--spacer-10);
      font-size: var(--font-size-16);
      line-height: var(--font-size-19);

      @include for-desktop {
        margin-bottom: var(--spacer-18);
        font-size: var(--font-size-24);
        line-height: var(--font-size-25);
      }
    }

    ::v-deep .sf-heading__subtitle {
      color: var(--black);
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--font-size-19);
      }
    }
  }

  &__change-city {
    margin-top: var(--spacer-20);
    width: px2rem(137);
    height: var(--spacer-40);

    @include for-desktop {
      font-size: var(--font-size-18);
      margin-top: var(--spacer-30);
      width: px2rem(228);
      height: var(--spacer-56);
    }

    @media (max-width: $mobile-max) {
      padding: var(--spacer-11) var(--spacer-sm);
      font-size: var(--font-size-14);
      line-height: var(--font-size-24);
    }
  }
}

.bottom-plp-block {
  background-color: var(--light-gray);
  margin-left: calc(-1 * var(--spacer-10));
  margin-right: calc(-1 * var(--spacer-10));
  width: calc(100% + calc(2 * var(--spacer-10)));
  padding-top: var(--spacer-40);
  padding-bottom: var(--spacer-5);
  position: relative;

  @media (min-width: $tablet-min) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: var(--spacer-5);
  }

  @include for-desktop {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: var(--spacer-5);
  }

  .sf-link--primary {
    font-size: var(--font-sm);
    padding: var(--spacer-20) 0 0;

    @media (min-width: $tablet-min) {
      padding-top: 0;
      padding-left: var(--spacer-30);
    }
  }

  .sf-pagination {
    padding-right: 0;

    @media (min-width: $tablet-min) {
      padding-bottom: var(--spacer-20);
      padding-top: var(--spacer-20);
      align-items: center;
    }

    ::v-deep {
      .router-link-active,
      .sf-pagination__item {
        color: var(--orange);
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--orange-hover);
        }
      }
      .sf-pagination__item--current {
        color: var(--black);
      }
      .sf-pagination__item:not(.sf-pagination__item--current):not(.sf-link) {
        color: var(--black);
      }
      .sf-pagination__item {
        &--next--disable,
        &--prev--disable {
          &:after {
            border-color: var(--dark-gray);
          }
        }
      }
    }
  }
}

.filters {
  &__title {
    --heading-title-font-size: var(--font-xl);
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;

    &:first-child {
      margin: calc(var(--spacer-xl) + var(--spacer-base)) 0 var(--spacer-xs) 0;
    }
  }

  &__color {
    margin: var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) 0;
    border: 1px solid var(--c-light);
  }

  &__item {
    --filter-label-color: var(--c-secondary-variant);
    --filter-count-color: var(--c-secondary-variant);
    --checkbox-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid var(--c-light);

    &:last-child {
      border-bottom: 0;
    }

    @include for-desktop {
      --checkbox-padding: 0;
      margin: var(--spacer-sm) 0;
      border: 0;
      padding: 0;
    }
  }

  &__accordion-item {
    --accordion-item-content-padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }

  &__buttons {
    margin: var(--spacer-sm) 0;
  }

  &__button-clear {
    --button-background: var(--c-light);
    --button-color: var(--c-dark-variant);
    margin: var(--spacer-xs) 0 0 0;
  }
}

.center {
  padding: 0 var(--spacer-sm);
  box-sizing: border-box;
  @include for-desktop {
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

.m-category-advantages {
  padding-top: 0;
}

.banner-info-wrap {
  margin: px2rem(10) 0 px2rem(20);
  position: relative;
  transform: none;

  &.banner-missed {
    .slider-carousel {
      display: none;
    }
  }

  &--last {
    margin-bottom: px2rem(50);

    &.banner-missed {
      margin: calc(-1 * var(--spacer-30)) 0 var(--spacer-30);
      border-top: 1px solid var(--color-zircon);
      position: relative;
      z-index: -1;
    }

    ::v-deep {
      .glide__track {
        padding-top: 0;
      }
    }
  }

  ::v-deep {
    .sf-banner-plp {
      background-position: right;

      @media (max-width: $mobile-max) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-25) var(--spacer-20) ;
      }

      @media (min-width: $tablet-min) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-30) var(--spacer-25) ;
      }

      @include for-desktop {
        min-height: px2rem(150);
        padding: 0 var(--spacer-56) var(--spacer-25) ;
      }

      &__description {
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25)
        }
      }
    }

    .sf-banner__container {
      padding-right: var(--spacer-50);
      flex: 1 1 var(--banner-container-width, 100%);
    }
  }

  @include for-tablet {
    transform: none;
  }

  @include for-desktop {
    margin-top: 0;
  }

  &:empty {
    margin: 0;
  }
}

.m-filter-categories {
  ::v-deep {
    .sf-accordion.accordion {
      max-height: 21.25rem;
      overflow-y: scroll;
      border-radius: var(--spacer-10);
      @include for-desktop {
        .accordion-item {
          &__header:hover {
            color: var(--orange);
          }
        }
      }
    }
  }
}

#category {
  .o-category-root-page {
    ::v-deep .sf-product-card {
      @include for-tablet {
        max-width: 100%;
      }

      @include for-desktop {
        max-width: 100%;
      }
    }

    ::v-deep .o-category-root-page__special-promotions {
      .glide__slide {
        flex-shrink: initial;
      }
    }
  }

  &.category--is-empty.category--varus-cafe {
    .products__navbar-container.desktop-only,
    .sidebar {
      display: none;
    }
  }

  &.category--varus-cafe {
    .navbar.section {
      display: none;
    }

    .sidebar-filters {
      background-color: var(--white);
    }

    ::v-deep {
      .sf-breadcrumbs__list-item {
        padding-left: px2rem(10);

        @include for-desktop {
          padding-left: 0;
        }

        @media (max-width: $tablet-min) {
          padding-left: px2rem(18);
        }
      }

      .m-filter-categories {
        display: none;
      }

      .products__navbar-container.desktop-only {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .navbar__sort {
        .sf-select {
          > div {
            top: -7px
          }
        }
      }

      .navbar__filter {
        .sf-checkbox__checkmark {
          &:before {
            border: 1px solid var(--gray);
            top: 1px;
          }

          &:hover {
            border: 1px solid var(--orange);
          }
        }
      }

      .products__grid.main-grid,
      .products__grid.top-grid {
        padding-top: 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: px2rem(15);
        row-gap: px2rem(20);

        @include for-desktop {
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: px2rem(370);
        }

        @include for-tablet {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: $tablet-min) {
          column-gap: px2rem(10);
        }

        .sf-product-card--out-of-stock-container {
          background: var(--white);
          height: auto;
        }

        .sf-product-card {
          &:hover {
            &:before {
              bottom: calc(-1 * var(--spacer-60));
            }
          }
        }

        .sf-product-card__wrapper {
          max-width: 100%;
          width: 100%;
          margin-bottom: 0;
          background-color: var(--white);

          .sf-product-card__title {
            margin-top: var(--spacer-10);
          }
        }
      }

      .banner-info-wrap {
        @include for-desktop {
          margin-top: px2rem(45);
          margin-bottom: 30px;
        }

        &:not(.banner-info-wrap--last) {
          display: none;
        }

        &--last {
          margin-bottom: var(--spacer-20);
          margin-top: var(--spacer-20);

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-40);
          }

          .sfo-carousel__bullets {
            padding-top: var(--spacer-10);
          }
        }

        &.banner-missed {
          margin-top: 0;

          @include for-desktop {
            margin-top: var(--spacer-20);
            margin-bottom: 0;
          }
        }

        .sf-carousel-item {
          overflow: unset;
        }
      }

      .sidebar-filters.desktop-only {
        margin-top: 0;
      }

      &__sidebar {
        flex: auto;
        max-width: inherit;
        padding: var(--spacer-sm) 0;

        @include for-tablet {
          padding-left: var(--spacer-17);
          padding-right: 0;
        }

        @include for-desktop {
          flex: 0 0 13.75rem;
          padding-left: var(--spacer-10);
          padding-top: var(--spacer-sm);
          padding-right: var(--spacer-20);
          max-width: 13.75rem;
        }

        .m-filter-categories--root {
          @include for-desktop {
            margin-left: 0;
          }

          ::v-deep .accordion {
            &-item {
              &__header:hover {
                @include for-desktop {
                  color: var(--orange);
                }
              }
            }
          }
        }
      }

      .o-category-root-page,
      .main.section {
        .sf-product-card {
          .sf-link,
          .price-block {
            text-align: center;
          }

          &__title-wrapper {
            margin-bottom: 0;
            display: flex;
            justify-content: center;

            @include for-desktop {
              height: px2rem(59);
            }
          }

          .price-block .sf-price {
            justify-content: center;
          }

          &__title {
            font-size: var(--font-size-13);
            margin-top: var(--spacer-10);
            height: px2rem(38);
            overflow: hidden;
            line-height: var(--font-size-19);

            @include for-tablet {
              margin-top: var(--spacer-15);
            }

            @media (min-width: $tablet-min) {
              max-width: 200px;
              font-size: var(--font-size-16);
            }

            @include for-desktop {
              margin-top: var(--spacer-23);
            }
          }

          &__block {
            background: var(--white);
          }

          &__image-wrapper {
            padding: var(--spacer-10);

            .sf-image {
              overflow: inherit;

              img {
                left: 50%;
                transform: translate(-50%, 0);
                width: auto;
              }
            }
          }

          &__quantity {
            margin-top: var(--spacer-5);

            @media (min-width: $tablet-min) {
              margin-top: var(--spacer-10);
            }
          }

          .m-product-add-to-cart {
            padding-bottom: var(--spacer-20);

            .a-product-quantity__wrapper,
            .sf-button {
              margin: 0 auto;
            }
          }
        }
      }

      .main.section {
        margin-bottom: var(--spacer-50);

        @include for-desktop {
          margin-bottom: var(--spacer-90);
        }

        @media (min-width: $tablet-min) {
          padding: 0 var(--spacer-15) var(--spacer-50);
        }

        .sidebar {
          .m-sidebar-mobile-buttons {
            display: none;
          }
        }

        .products {
          @include for-desktop {
            padding-bottom: var(--spacer-60);
          }

          .m-category-list__item {
            padding: 0;
          }

          .sf-product-card--out-of-stock-container {
            &::before {
              display: none;
            }

            .sf-product-card__footer {
              text-align: center;
              background: var(--white);
              padding-bottom: 1rem;
            }
          }

          .products__grid {
            .sf-product-card__wrapper {
              height: 100%;

              .sf-product-card {
                --product-card-padding: 0;
                padding-bottom: 0;
                position: static;

                &__price {
                  margin-top: var(--spacer-15);
                  margin-bottom: var(--spacer-15);
                }

                &:hover {
                  &__hidden-basket {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

        .m-category-list__item {
          width: 100%;
          max-width: 100%;
        }
      }

      .o-category-root-page {
        margin-bottom: var(--spacer-70);

        &__not-found_change-city {
          --button-border-radius: 0;
          margin-bottom: px2rem(30);
        }

        &__sidebar,
        &__title {
          display: none;
        }

        &__wrapper {
          padding-top: 0;
        }

        .o-section--center {
          .o-section__heading {
            padding-left: 0;
          }
        }

        .product-carousel {
          margin-bottom: 0;

          .sf-carousel__wrapper {
            padding: 0;
          }

          .glide__slides {
            margin-left: 0;

            .sf-carousel-item {
              margin: 0;
              max-width: inherit;

              @media (max-width: $tablet-max) {
                background-color: var(--white);
              }

              @include for-desktop {
                padding-bottom: var(--spacer-20);
              }
            }
          }
        }

        .sf-product-card {
          &__price {
            margin-top: var(--spacer-10);
            margin-bottom: var(--spacer-20);

            @include for-tablet {
              margin-top: var(--spacer-12);
              margin-bottom: var(--spacer-12);
            }

            @include for-desktop {
              margin-top: var(--spacer-15);
            }
          }
        }

        &__content {
          padding: 0 var(--spacer-10) var(--spacer-50);

          @media (min-width: $tablet-min) {
            padding: 0 var(--spacer-15) var(--spacer-50);
          }

          .o-section--center {
            max-width: inherit;
          }
        }

        &__not-found {
          @include for-desktop {
            padding-bottom: 2rem;
          }
        }

        .o-category-root-page__special-promotions {
          @include for-mobile {
            margin-bottom: var(--spacer-40);
          }
        }
      }
    }
  }
}
</style>
