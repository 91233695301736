<template>
  <div
    class="m-filter-categories"
    :class="[{'m-filter-categories--root': isCurrentCategoryRoot},
             {'m-filter-categories--empty': categories.length === 0}]"
  >
    <SfAccordion
      :show-chevron="false"
      class="accordion"
      :open="openedCategories"
    >
      <SfAccordionItem
        v-for="category in categories"
        :key="category.id"
        :header="category.name"
        class="accordion-item"
      >
        <template #header="{ header, isOpen }">
          <router-link
            :to="category.link"
            :aria-pressed="isOpen.toString()"
            :aria-expanded="isOpen.toString()"
            :class="{ 'is-open': isOpen }"
            class="accordion-item__header"
            @click="toggleCategoriesFiltersFromLink"
          >
            <span class="accordion-item__label">
              {{ header }}
              <span class="accordion-item__label-count">
                {{ getProductsCount(category) || '' }}
              </span>
            </span>
          </router-link>
        </template>
        <SfAccordion
          v-if="category.items"
          :show-chevron="false"
          class="accordion--sub"
          :class="{'accordion-with-child': category.items.length}"
          :open="openedCategories"
        >
          <SfAccordionItem
            v-for="item in category.items"
            :key="item.id"
            :header="item.name"
            class="accordion-item--sub"
          >
            <template #header="{ header, isOpen }">
              <router-link
                :to="item.link"
                :aria-pressed="isOpen.toString()"
                :aria-expanded="isOpen.toString()"
                :class="{ 'is-open': isOpen }"
                class="accordion-item__header"
                @click="toggleCategoriesFiltersFromLink"
              >
                <span
                  class="accordion-item__label"
                >
                  {{ header }}
                  <span class="accordion-item__label-count">
                    {{ getProductsCount(item) || '' }}
                  </span>
                </span>
              </router-link>
            </template>
            <SfAccordion
              v-if="item.items"
              :show-chevron="false"
              class="accordion--sub"
              :class="{'accordion-with-child': item.items.length}"
              :open="openedCategories"
            >
              <SfAccordionItem
                v-for="subItem in item.items"
                :key="subItem.id"
                :header="subItem.name"
                class="accordion-item--sub"
              >
                <template #header="{ header }">
                  <router-link
                    :to="subItem.link"
                    class="accordion-item__header"
                    @click="toggleCategoriesFiltersFromLink"
                  >
                    <span
                      class="accordion-item__label"
                    >
                      {{ header }}
                      <span class="accordion-item__label-count">
                        {{ getProductsCount(subItem) || '' }}
                      </span>
                    </span>
                  </router-link>
                </template>
              </SfAccordionItem>
            </SfAccordion>
          </SfAccordionItem>
        </SfAccordion>
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { SfAccordion } from '@storefront-ui/vue';
import { prepareCategoryMenuItem } from 'theme/helpers';
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'MCategoryFilterCategories',
  components: {
    SfAccordion
  },
  data () {
    return {
      rootCategory: null,
      autoScrollCategoryInterval: null
    }
  },
  props: {
    categoriesMap: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      categoriesProductsCount: 'category-extension/getCategoriesProductsCount'
    }),
    categories () {
      return this.rootCategory ? this.prepareCategories(this.rootCategory.children_data || []) : [];
    },
    openedCategories () {
      if (!this.rootCategory || !this.getCurrentCategory.path) {
        return [];
      }
      const ids = this.getCurrentCategory.path.split('/').map(Number);
      const categoryNames = [];
      this.findActiveCategory(categoryNames, ids, this.categories);
      return categoryNames;
    },
    isCurrentCategoryRoot () {
      return this.getCurrentCategory.parent_id === config.entities.category.categoriesRootCategoryId;
    }
  },
  mounted () {
    this.autoScrollCategory()
  },
  beforeDestroy () {
    clearInterval(this.autoScrollCategoryInterval);
  },
  methods: {
    ...mapActions({
      loadCategoriesProductsCount: 'category-extension/loadCategoriesProductsCount'
    }),
    getProductsCount (category) {
      return this.categoriesProductsCount[category.id] || 0
    },
    prepareCategories (categories) {
      return categories
        .map(category => {
          const preparedCategory = prepareCategoryMenuItem(category);
          preparedCategory.items = preparedCategory.items ? this.prepareCategories(preparedCategory.items) : [];
          return preparedCategory;
        })
        .sort((a, b) => a.position - b.position);
    },
    findActiveCategory (categoryNames, ids, categories) {
      categories.forEach(category => {
        if (ids.includes(category.id)) {
          categoryNames.push(category.name);
          if (category.items.length) {
            this.findActiveCategory(categoryNames, ids, category.items);
          }
        }
      });
    },
    toggleCategoriesFiltersFromLink () {
      // TODO: Remove this crutch; Change behavior of component
      setTimeout(() => this.$emit('toggle-categories'), 50)
    },
    autoScrollCategory () {
      if (isServer) return
      this.autoScrollCategoryInterval = setInterval(() => {
        const activeLink = document.querySelector('.m-filter-categories-all .router-link-exact-active');
        if (activeLink !== null) {
          const containerOffsetTop = document.querySelector('.m-filter-categories-all .accordion').getBoundingClientRect().top;
          const elementOffsetTop = activeLink.getBoundingClientRect().top;
          const offsetTop = elementOffsetTop - containerOffsetTop;
          document.querySelector('.m-filter-categories-all .accordion').scrollTo({
            top: offsetTop,
            left: 0,
            behavior: 'smooth'
          });
          clearInterval(this.autoScrollCategoryInterval);
        }
      }, 200);
    },
    getRootCategoryId () {
      const ids = this.getCurrentCategory.path.split('/');
      return ids.length > 1 ? Number(ids[2]) : this.getCurrentCategory.id;
    },
    getRootCategory (rootCategoryId) {
      return this.getCurrentCategory.id === rootCategoryId
        ? this.getCurrentCategory
        : this.categoriesMap[rootCategoryId] || null;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler: function () {
        this.loadCategoriesProductsCount(this.rootCategory);
      }
    },
    'getCurrentCategory': {
      immediate: true,
      handler: function (newCat, oldCat) {
        const { id: newId = null, path = null } = newCat ?? {};
        const { id: oldId = null } = oldCat ?? {};

        if (!path) {
          return null;
        }

        const rootCategoryId = this.getRootCategoryId();
        if (this.rootCategory === null || oldId !== newId) {
          this.rootCategory = this.getRootCategory(rootCategoryId);
          this.loadCategoriesProductsCount(this.rootCategory);
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-filter-categories {
  overflow: auto;
  padding: 0;
  margin: 0 0 18px;
  @include for-desktop {
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
  }

  @media only screen and (min-width: $tablet-min) {
    margin: 0 0 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  .accordion {
    font-family: Inter,serif;
    display: block;
    width: auto;
    align-items: center;
    padding-right: var(--spacer-5);
    padding-left: var(--spacer-20);
    padding-bottom:  var(--spacer-20);
    background: var(--accordion-background);
    border-radius: var(--spacer-10);
    scrollbar-width: thin;
    scrollbar-color:  rgba(235, 103, 71, 0.3);
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: var(--orange);
      }
    }

    @include for-desktop {
      padding-right: 0;
      padding-left: 0;
      background: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 103, 71, 0.3);
    }

    &-item {
      &:first-child {
        @include for-desktop {
          margin-left: var(--spacer-20);
        }
      }

      &:not(:first-child) {

        @include for-desktop {
          margin-left: var(--spacer-20);
          margin-right: var(--spacer-20);
        }
      }

      &__header {
        color: var(--black);
        white-space: normal;
        padding: var(--spacer-20) 0 0;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-sm);
        display: inline-block;
        border-radius: var(--spacer-10);
        position: relative;
        width: 80%;
        line-height: var(--font-lg);
        transition: color 0.3s ease-in-out;

        .accordion-item__label {
          position: relative;

          &-count {
            color: var(--neutral-gray);
            padding-left: var(--spacer-10);
            position: absolute;
            left: 100%;
            bottom: 0;
            transition: color .3s ease-in-out;
          }
        }

        &.router-link-active {
          color: var(--orange);
          background-color: inherit;

          .accordion-item__label {

            &-count {
              color: var(--orange);
              transition: color .3s ease-in-out;
            }
          }
        }
        @include for-desktop {
          &:hover {
            .accordion-item__label {

              &-count {
                color: var(--orange);
              }
            }
          }
        }
      }

      ::v-deep .sf-accordion-item__content {
        padding-bottom: 0;
        padding-top: 0;
        border: 0 solid var(--gray);

        & > .accordion--sub {
          padding-left: var(--spacer-20);

          &.accordion-with-child {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 1px;
              left: 0;
              height: calc(100% + var(--spacer-17));
              width: 1px;
              background: var(--category-divider-color);
            }
          }

          .sf-accordion-item:first-child a {
            padding-top: var(--spacer-20);
          }

          .sf-accordion-item:last-child a {
            padding-bottom: 0;
          }

          .accordion-item__header.is-open {
            border-radius: 0;
          }
        }
      }
    }
  }

  &--root {
    padding-left: var(--spacer-10);
    @include for-desktop {
      padding: 0 0 var(--spacer-20);
      display: block;
    }

    .accordion {
      display: inline-flex;
      flex-direction: row;
      padding: var(--spacer-2xs);

      @include for-desktop {
        display: block;
        height: initial;
        padding: 0;
      }

      &-item {
        position: relative;
        &:last-child {
          &:after {
            display: none;
          }
        }
        &:after {
          content: '';
          opacity: 0.1;
          height: 1.75rem;
          position: absolute;
          right: 0;
          width: 1px;
          background: var(--orange);
          top: 50%;
          transform: translateY(-50%);
          @include for-desktop {
            display: none;
          }
        }

        @include for-desktop {
          line-height: initial;
        }

        &__header {
          white-space: nowrap;
          color: var(--orange);
          position: relative;
          padding: var(--spacer-12) var(--spacer-15);
          width: auto;

          @include for-desktop {
            color: var(--black);
            white-space: normal;
            padding: var(--spacer-20) 0 0;
            width: 80%;
          }

          &.is-open {
            color: var(--black);
            background-color: var(--white);
            margin-top: .25rem;
            margin-bottom: .25rem;
            padding-left: var(--spacer-15);
            padding-right: var(--spacer-15);

            @include for-desktop {
              color: var(--orange);
              background-color: inherit;
              margin-top: 0;
              margin-bottom: 0;
              padding-left: 0;
              padding-right: var(--spacer-20);
            }

            &:after {
              display: none;
            }
          }

          .accordion-item__label {
            @include for-mobile {
              padding: 0;

              &-count {
                position: relative;
                padding: 0;
                left: 0;
                margin-left: var(--spacer-15);
                transition: color .3s ease-in-out;
              }
            }
          }
        }

        &:last-child .accordion-item__header:after {
          display: none;
        }

        ::v-deep .sf-accordion-item__content {
          margin-left: 0;
          border-left: none;

          &:empty {
            display: none;
          }

          @include for-desktop {
            margin-left: 15px;
            border-left: 1px solid var(--gray);
          }
        }
      }
    }
  }

  &--empty {
    display: none;
  }
}
</style>
