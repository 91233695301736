<template>
  <div
    class="o-varus-cafe-filter"
    :class="{'o-varus-cafe-filter--lvl2': isVarusCategoryLvl2}"
  >
    <div class="o-varus-cafe-filter__navbar">
      <SfHeading
        :level="1"
        :title="categoryMetaContent.h1"
        class="navbar__title"
      />
      <div class="navbar__counter">
        <span class="navbar__label">
          {{ categoryProductsTotal }}
        </span>
      </div>
    </div>
    <div class="o-varus-cafe-filter__title">
      <SfHeading
        title="VARUS CAFE"
        :level="1"
        class="o-varus-cafe-filter-title__heading"
      />
      <MVarusCafeTopFilter
        :toggle-categories="toggleCategories"
        :open-filter="openFilter"
        :change-filters="changeFilters"
        :available-filters="availableFilters"
        :varus-cafe-category="varusCafeCategory"
        v-bind="$props"
        v-on="$listeners"
      />
    </div>
  </div>
</template>
<script>
import { SfHeading } from '@storefront-ui/vue';
import { BREAKPOINT_XSM, BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG } from 'theme/helpers/breakpoints'
import MVarusCafeTopFilter from 'theme/components/molecules/m-varuscafe-top-filter';

export default {
  name: 'OVarusCafeCategory',
  components: {
    MVarusCafeTopFilter,
    SfHeading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    availableFilters: {
      type: Object,
      default: () => {
        return {
          kitchenVaruscafe: '',
          menuVaruscafe: ''
        }
      }
    },
    categoryMetaContent: {
      type: Object,
      default: () => {
        return {}
      }
    },
    categoryProductsTotal: {
      type: String,
      default: ''
    },
    isVarusCategoryLvl2: {
      type: Boolean,
      default: false
    },
    filtersCount: {
      type: Number,
      default: null
    },
    varusCafeCategory: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        perView: 4,
        gap: 10,
        dragThreshold: false,
        breakpoints: {
          [BREAKPOINT_LG - 1]: {
            perView: 4
          },
          [BREAKPOINT_MD - 1]: {
            perView: 3
          },
          [BREAKPOINT_SM - 1]: {
            perView: 2.3,
            swipeThreshold: false,
            dragThreshold: false
          },
          [BREAKPOINT_XSM - 1]: {
            perView: 2,
            gap: 15,
            swipeThreshold: false,
            dragThreshold: false
          }
        }
      },
      resizeObserver: null,
      categoryRootPage: null,
      mainSection: null,
      categoryRelatedProducts: []
    }
  },
  mounted () {
    this.categoryRootPage = this.assignElement('.o-category-root-page')
    this.mainSection = this.assignElement('.main.section')
    this.updateBackgroundColor()
  },
  updated () {
    this.updateBackgroundColor()
  },
  methods: {
    updateBackgroundColor () {
      const containerClass = this.setBackgroundColor()
      if (containerClass) this.resizeObserver.observe(containerClass);
    },
    destroyUpdateBackgroundColor () {
      const containerClass = this.setBackgroundColor(this.categoryRootPage, this.mainSection)
      if (containerClass) this.resizeObserver.unobserve(containerClass);
    },
    setBackgroundColor (element1, element2) {
      const categoryRootPage = element1 || this.assignElement('.o-category-root-page')
      const mainSection = element2 || this.assignElement('.main.section')
      const preHeaderBanner = this.assignElement('.pre-header-banner')?.offsetHeight || 0

      const containerClass = categoryRootPage || mainSection

      this.resizeObserver = new ResizeObserver(() => {
        const node = document?.createElement('div')

        this.assignElement('.highlight-varuscafe')?.remove()

        node.classList.add('highlight-varuscafe')

        if (this.$route.name.includes('varus-cafe')) {
          this.assignElement('#viewport').appendChild(node)
        }

        const heightTopFilters = this.assignElement('.o-varus-cafe-filter')?.offsetHeight

        const containerHeight = heightTopFilters + containerClass.offsetHeight + preHeaderBanner

        node.style.height = `${containerHeight}px`
      })

      return containerClass
    },
    changeFilters (filter) {
      this.$emit('change', { filter })
    },
    openFilter () {
      this.$emit('open')
    },
    assignElement (elementSelector) {
      return document.querySelector(elementSelector);
    },
    toggleCategories () {
      this.$emit('toggle-categories')
    }
  },
  beforeDestroy () {
    this.destroyUpdateBackgroundColor()
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-varus-cafe-filter {
  position: relative;
  margin: 0 var(--spacer-10) px2rem(20) var(--spacer-10);
  width: auto;

  @include for-desktop {
    padding: 0 var(--spacer-5);
    margin-bottom: var(--spacer-30);
  }

  @include for-tablet {
    margin: 0 var(--spacer-17) px2rem(20) var(--spacer-17);
  }

  @include for-desktop {
    padding-left: 0;
    padding-right: 0;
  }

  &__title {
    box-sizing: border-box;
    background: url("/assets/varuscafe/varuscafe-mobile.png") no-repeat 100%/100%;
    border-radius: 5px;
    height: px2rem(136);
    padding: var(--spacer-20);
    min-width: 136px;
    position: relative;

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-32);
      padding: var(--spacer-30);
      background: url("/assets/varuscafe/varus-cafe.png") no-repeat right;
      background-size: cover;
    }

    @include for-desktop {
      height: px2rem(163)
    }

    @include for-tablet {
      height: px2rem(158)
    }

    ::v-deep {
      .sf-heading {
        display: inline-flex;
        padding-bottom: px2rem(20);

        &__title {
          font-size: var(--font-size-20);
          color: var(--white);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-32);
            line-height: var(--font-size-24);
          }
        }
      }
    }
  }

  &--lvl2 {
    margin-bottom: var(--spacer-20);

    @media (max-width: $tablet-min) {
      ::v-deep {
        .m-sidebar-mobile-buttons__button {
          padding-left: 20px;
        }
      }

      .o-category-varus-cafe__title {
        background-image: unset;
        padding: 0;
        height: auto;

        .sf-heading {
          display: none;
        }
      }

      .o-category-varus-cafe__navbar {
        display: flex;
        align-items: baseline;
        margin-bottom: var(--spacer-10);

        .navbar__title {
          margin-right: px2rem(20);
          padding-bottom: 0;

          .sf-heading__title {
            color: var(--black);
            font-size: var(--font-size-20);

            @include for-tablet {
              font-size: var(--font-size-32);
            }
          }
        }

        .navbar__label {
          font-size: var(--font-size-13);
          color: var(--dark-gray);
        }
      }
    }
  }

  &__navbar {
    display: none;
  }
}
</style>
